import { useApolloClient, useMutation } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Web3 from 'web3'

import {
    approveUSDT,
    getAllowanceUSDT,
    getUsdtBalance,
    upgradeLevel,
    w3,
} from '../../../../apis/contract'
import {
    CONFIRM_UPGRADE,
    CREATE_UPGRADE_ROW,
    GET_UPGRADE_DATA,
} from '../../../../apis/graphql/requests'
import { useAppConfig } from '../../../../contexts/AppConfigProvider'
import { useNavigate } from 'react-router-dom'

const Upgrade = () => {
    const {
        userData,
        isRegistrationPaymentNeeded,
        updateLoadingState,
        updateCurrentPage,
    } = useAppConfig()
    const client = useApolloClient()
    const navigate = useNavigate()

    const [createUpgradeRow] = useMutation(CREATE_UPGRADE_ROW)
    const [confirmUpgrade] = useMutation(CONFIRM_UPGRADE)

    const [upgradeData, setUpgradeData] = useState({
        referralParent: '',
        levelParent: '',
    })

    const returnToHome = () => {
        if (!isRegistrationPaymentNeeded) {
            updateCurrentPage('home')
            navigate('/user/home')
        }
    }

    const confirmUpgradeLevel = async () => {
        if (userData.addr) {
            try {
                updateLoadingState(true)

                const amount = 20

                // USDT Amount Check
                await getUsdtBalance(userData.addr).then((balance) => {
                    const curAmountEther = Web3.utils.fromWei(
                        balance.toString(),
                        'mwei'
                    )
                    if (parseFloat(curAmountEther) < amount)
                        throw new Error("USDT amount doesn't enough!")
                })

                // Approve USDT
                const allowance = await getAllowanceUSDT(userData.addr)
                const ethAllowance = Web3.utils.fromWei(allowance, 'mwei')

                if (parseFloat(ethAllowance) < amount) {
                    const weiAmount = Web3.utils.toWei(
                        amount.toString(),
                        'mwei'
                    )
                    await approveUSDT(userData.addr, weiAmount)
                }
                // Record to Backend the upgrade row
                const message = 'Upgrade confirm process'

                const signature = await w3.eth.personal.sign(
                    message,
                    userData.addr ?? '',
                    ''
                )
                await createUpgradeRow({
                    variables: {
                        upgrade: {
                            message,
                            walletAddress: userData.addr,
                            signature,
                        },
                    },
                }).then(async (response) => {
                    const data = response.data.upgradeLevel
                    const id = data.id
                    await upgradeLevel(
                        userData.addr ?? '',
                        upgradeData.levelParent,
                        upgradeData.referralParent,
                        id
                    ).then(async () => {
                        await confirmUpgrade({
                            variables: {
                                confirm: {
                                    message,
                                    walletAddress: userData.addr,
                                    signature,
                                    id,
                                },
                            },
                        })
                        toast.success(
                            'To confirm the upgrade process, it will take 3 minutes, please wait!',
                            { toastId: 'confirm upgrade alert' }
                        )
                    })
                })

                updateLoadingState(false)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (e: any) {
                toast.warning(e.message)
                updateLoadingState(false)
            }
            returnToHome()
        }
    }

    const getIntialData = useCallback(async () => {
        if (userData.addr) {
            updateLoadingState(true)
            await client
                .query({
                    query: GET_UPGRADE_DATA,
                    fetchPolicy: 'network-only',
                    variables: {
                        address: userData.addr,
                    },
                })
                .then((response) => {
                    const data = response.data.getUpgradData
                    if (data) {
                        updateCurrentPage('upgrade levels')
                        setUpgradeData({
                            referralParent: data.referralParent.walletAddress,
                            levelParent: data.levelParent.walletAddress,
                        })
                    }
                })
                .catch((e) => {
                    toast.warn(e.message)
                })
                .finally(() => {
                    updateLoadingState(false)
                })
        }
    }, [client, updateCurrentPage, updateLoadingState, userData.addr])

    useEffect(() => {
        getIntialData()
    }, [getIntialData])

    return (
        <div className="flex w-full flex-col">
            <div className="mt-5 flex flex-row justify-start pl-6 text-xl font-bold text-yellow-500">{`Upgrade From V${
                userData.level
            } to V${Number(userData.level ?? 0) + Number(1)}`}</div>
            <div className="mt-6 flex flex-row justify-start pl-6 font-bold text-white">
                Account Information
            </div>
            <div className="mx-2">
                <hr className="mt-2 h-px border-0 bg-neutral-600 dark:bg-neutral-600" />
            </div>
            <div className="mt-3 flex flex-row px-8 text-sm text-white">
                Level Parent Wallet Address
            </div>
            <div className="mt-2 flex w-full flex-row px-8">
                <div className="h-10 w-full overflow-hidden text-ellipsis whitespace-nowrap rounded-lg bg-neutral-500 px-3 py-2 text-center text-white">
                    {`${upgradeData.levelParent.slice(
                        0,
                        9
                    )}...${upgradeData.levelParent.slice(-7)}`}
                </div>
            </div>
            <div className="mt-5 flex flex-row px-8 text-sm text-white">
                Amount
            </div>
            <div className="mt-2 mb-10 flex w-full flex-row px-8">
                <div className="h-10 w-1/6 rounded-l-lg bg-neutral-500 px-2 pt-3 text-center text-sm text-white">
                    USDT
                </div>
                <div className="h-10 w-5/6 rounded-r-lg border-l-2 border-l-red-600 bg-neutral-500 px-3 py-2 text-white">
                    5
                </div>
            </div>

            <div className="mt-3 flex flex-row px-8 text-sm text-white">
                Referral Parent Wallet Address
            </div>
            <div className="mt-2 flex w-full flex-row px-8">
                <div className="h-10 w-full overflow-hidden text-ellipsis whitespace-nowrap rounded-lg bg-neutral-500 px-3 py-2 text-center text-white">
                    {`${upgradeData.referralParent.slice(
                        0,
                        9
                    )}...${upgradeData.referralParent.slice(-7)}`}
                </div>
            </div>
            <div className="mt-5 flex flex-row px-8 text-sm text-white">
                Amount
            </div>
            <div className="mt-2 mb-10 flex w-full flex-row px-8">
                <div className="h-10 w-1/6 rounded-l-lg bg-neutral-500 px-2 pt-3 text-center text-sm text-white">
                    USDT
                </div>
                <div className="h-10 w-5/6 rounded-r-lg border-l-2 border-l-red-600 bg-neutral-500 px-3 py-2 text-white">
                    5
                </div>
            </div>

            <div
                onClick={confirmUpgradeLevel}
                className="flex w-full flex-row px-5">
                <div className="mt-12 flex w-full flex-row justify-center rounded-full border-[2px] border-yellow-500 py-[8px] text-center font-bold text-yellow-500 hover:cursor-pointer hover:border-white hover:text-white">
                    Confirm Upgrade
                </div>
            </div>
            <div
                onClick={returnToHome}
                className="mb-4 flex w-full flex-row px-5">
                <div className="mt-8 flex w-full flex-row justify-center rounded-full border-[2px] border-red-400 py-[8px] text-center font-bold text-red-400 hover:cursor-pointer hover:border-red-300 hover:text-red-300">
                    Cancel
                </div>
            </div>
        </div>
    )
}

export default Upgrade
