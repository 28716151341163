import { ApolloProvider, split, HttpLink } from '@apollo/client'
import { ApolloClient, InMemoryCache } from '@apollo/client/core'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import React, { FC, PropsWithChildren, createContext, useRef } from 'react'

export const AlantixApolloContext = createContext({})

export const useApollo = () => React.useContext(AlantixApolloContext)

const AlantixApolloProvider: FC<PropsWithChildren<unknown>> = ({
    children,
}) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const clientRef = useRef<ApolloClient<any> | null>()

    if (!clientRef.current) {
        const httpLink = new HttpLink({
            uri: process.env.REACT_APP_BACKEND_HTTP_URL,
        })

        const wsLink = new GraphQLWsLink(
            createClient({
                url: process.env.REACT_APP_BACKEND_WS_URL ?? '',
            })
        )

        const splitLink = split(
            ({ query }) => {
                const definition = getMainDefinition(query)
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                )
            },
            wsLink,
            httpLink
        )

        clientRef.current = new ApolloClient({
            link: splitLink,
            cache: new InMemoryCache(),
        })
    }

    return (
        <AlantixApolloContext.Provider value={{}}>
            <ApolloProvider client={clientRef.current}>
                {children}
            </ApolloProvider>
        </AlantixApolloContext.Provider>
    )
}

export default AlantixApolloProvider
