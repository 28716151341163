import { Routes } from 'react-router-dom'

import { useRouteManage } from '../hooks/useRouteManage'

const View = () => {
    const { getRoutes } = useRouteManage()

    return (
        <div className="flex min-h-screen w-full flex-row items-center justify-center bg-white">
            <div className="relative h-screen w-screen overflow-y-scroll text-white sm:w-[500px]">
                <Routes>{getRoutes('main-page')}</Routes>
            </div>
        </div>
    )
}

export default View
