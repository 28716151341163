import {
    createContext,
    useCallback,
    useContext,
    useState,
    useMemo,
    ReactNode,
    useEffect,
} from 'react'
import { useWeb3React } from '@web3-react/core'
import { InjectedConnector } from '@web3-react/injected-connector'
import { Web3Provider } from '@ethersproject/providers'

import { ChainIds, CurrentChainId, CurrentNetworkData } from '../config/web3'
import LocalStorage, {
    METAMASK_CONNECTED,
    WALLET_ADDRESS,
} from '../utils/local-storage'
import useEagerConnect from '../hooks/web3/useEagerConnect'
import useInactiveListener from '../hooks/web3/useInactiveListener'

type Props = {
    children: ReactNode
}

interface Web3ManageProps {
    account?: string | null
    chainId?: number
    library?: Web3Provider
    isConnected?: boolean
    activate: () => Promise<void>
    deactivate: () => void
}

export const Web3Manage = createContext<Web3ManageProps>({
    activate: async () => {},
    deactivate: () => {},
})

export const useWeb3Manage = () => useContext(Web3Manage)

export const Injected = new InjectedConnector({
    supportedChainIds: Object.values(ChainIds) as number[],
})

export default function Web3ManageProvider({ children }: Props) {
    const { activate, deactivate, account, chainId, library } =
        useWeb3React<Web3Provider>()

    const [connected, setConnected] = useState(
        LocalStorage.get(METAMASK_CONNECTED, false)
    )

    const [isActivatedByWeb, setIsActivatedByWeb] = useState(false)

    const checkedConnect = useEagerConnect(connected)

    const handleActivate = useCallback(async () => {
        return activate(Injected).then(() => {
            LocalStorage.set(METAMASK_CONNECTED, true)
            setConnected(true)
            setIsActivatedByWeb(true)
        })
    }, [activate])

    const handleDeactivate = useCallback(async () => {
        LocalStorage.set(METAMASK_CONNECTED, false)
        setConnected(false)
        return deactivate()
    }, [deactivate])

    const value = useMemo(() => {
        LocalStorage.set(WALLET_ADDRESS, account)
        const isConnected = !!account && chainId === CurrentChainId
        return {
            account,
            activate: handleActivate,
            deactivate: handleDeactivate,
            library,
            chainId,
            isConnected,
        }
    }, [account, chainId, handleActivate, handleDeactivate, library])

    useEffect(() => {
        if (!library) return
        if (isActivatedByWeb) {
            if (chainId !== CurrentChainId) {
                library
                    .send('wallet_switchEthereumChain', [
                        { chainId: CurrentNetworkData.chainId },
                        account,
                    ])
                    .catch((switchError) => {
                        if (switchError.code === 4902) {
                            library
                                .send('wallet_addEthereumChain', [
                                    CurrentNetworkData,
                                    account,
                                ])
                                .catch((addError) => {
                                    console.error(`Add chain error ${addError}`)
                                })
                        } else {
                            console.error(`Switch chain error ${switchError}`)
                        }
                    })
                    .finally(() => setIsActivatedByWeb(false))
            } else {
                setIsActivatedByWeb(false)
            }
        } else if (chainId !== CurrentChainId) {
            deactivate()
        }
    }, [account, chainId, deactivate, isActivatedByWeb, library])

    useInactiveListener(checkedConnect, connected)

    if (!checkedConnect) return null

    return <Web3Manage.Provider value={value}>{children}</Web3Manage.Provider>
}
