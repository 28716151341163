const LocalStorage = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set(key: string, value: any) {
        if (value === undefined) return
        const stringify = JSON.stringify(value)
        localStorage.setItem(key, stringify)
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get(key: string, defaultValue: any = null) {
        const stringify = localStorage.getItem(key)
        if (stringify === null) return defaultValue
        return JSON.parse(stringify)
    },
    clear() {
        localStorage.clear()
    },
    remove(key: string) {
        localStorage.removeItem(key)
    },
}

export const SPOT_ACTIVE_PAIR_KEYS = 'spotDashboard'
export const MARGIN_ACITVE_PAIR_KEYS = 'marginDashboard'
export const WALLET_ADDRESS = 'metamask_address'
export const METAMASK_CONNECTED = 'metamask_connected'

export default LocalStorage
