import { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import main from '../../assets/main.svg'
import mainLogo from '../../assets/main-logo.png'
import { useAppConfig } from '../../contexts/AppConfigProvider'
import { useWeb3Manage } from '../../contexts/Web3ManageProvider'
import { useAuth } from '../../contexts/AuthProvider'
import RegisterModal from '../../components/RegisterModal'

const Landing = () => {
    const { account, activate, isConnected } = useWeb3Manage()
    const { isRegistrationPaymentNeeded, updateLoadingState } = useAppConfig()
    const { updateModalState } = useAppConfig()
    const { authenticate, canLogin } = useAuth()
    const navigate = useNavigate()

    const [isHandleByUser, setIsHandleByUser] = useState<boolean>(false)

    const handleActivate = useCallback(() => {
        setIsHandleByUser(true)
        activate()
    }, [activate])

    useEffect(() => {
        const handleAuthenticate = async () => {
            setIsHandleByUser(false)

            if (account) {
                updateLoadingState(true)
                authenticate(account)
                updateLoadingState(false)
            }
        }

        if (isConnected && isHandleByUser) {
            handleAuthenticate()
        }
    }, [
        account,
        authenticate,
        isConnected,
        isHandleByUser,
        navigate,
        updateLoadingState,
        updateModalState,
    ])

    useEffect(() => {
        if (isConnected) {
            if (canLogin) {
                updateModalState(false)
                navigate(
                    isRegistrationPaymentNeeded
                        ? '/user/home/upgrade'
                        : '/user/home'
                )
            } else {
                updateModalState(true)
            }
        }
    }, [
        canLogin,
        isConnected,
        isRegistrationPaymentNeeded,
        navigate,
        updateModalState,
    ])

    return (
        <div
            className="relative flex h-full w-full flex-col items-center justify-around bg-cover bg-center"
            style={{ backgroundImage: `url(${main})` }}>
            {!isConnected && (
                <>
                    <img
                        src={mainLogo}
                        alt="MT"
                        className="text-center text-6xl font-bold text-red-600"
                    />
                    <div
                        className="rounded-full bg-yellow-200 px-16 py-3 text-lg font-bold text-black hover:cursor-pointer"
                        onClick={handleActivate}>
                        Register
                    </div>
                </>
            )}
            <RegisterModal />
        </div>
    )
}

export default Landing
