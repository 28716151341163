import SmartTravellerABIJsonMainnet from './mainnet/SmartTravellerABI.json'
import USDTABIJsonMainnet from './mainnet/USDTABI.json'
import SmartTravellerABIJsonTestnet from './testnet/SmartTravellerABI.json'
import USDTABIJsonTestnet from './testnet/USDTABI.json'
import { isMainnet } from '../config/web3'

export const SmartTravellerABI = isMainnet
    ? SmartTravellerABIJsonMainnet.abi
    : SmartTravellerABIJsonTestnet.abi

export const USDTABI = isMainnet
    ? USDTABIJsonMainnet.abi
    : USDTABIJsonTestnet.abi
