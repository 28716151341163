import { Routes } from 'react-router-dom'

import { useRouteManage } from '../../../hooks/useRouteManage'

const Auth = () => {
    const { getRoutes } = useRouteManage()

    return (
        <div>
            <Routes>{getRoutes('auth')}</Routes>
        </div>
    )
}

export default Auth
