import { useApolloClient } from '@apollo/client'
import 'rc-tree/assets/index.css'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { GET_USERS } from '../apis/graphql/requests'
import { useAppConfig } from '../contexts/AppConfigProvider'

const MemberTreeView = () => {
    const { userData } = useAppConfig()
    const client = useApolloClient()

    const [textArea, setTextArea] =
        useState<Array<Array<{ addr: string; level: string }>>>()

    const makeMemberTree = useCallback(async () => {
        await client
            .query({
                query: GET_USERS,
                fetchPolicy: 'network-only',
                variables: {},
            })
            .then((response) => {
                const data = response.data.getUsers
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const initTree: { [key: string]: any } = {}
                for (let i = 0; i < data.length; i++) {
                    const addrIndex: string = data[i].addr
                    initTree[addrIndex] = { ...data[i], children: [] }
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const tree = data.reduce((prev: any, item: any) => {
                    if (!!item.parentAddr) {
                        if (prev[item.parentAddr]) {
                            prev[item.parentAddr].children.push(prev[item.addr])
                        }
                    }
                    return prev
                }, initTree)

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const treeData = tree[userData.addr!]
                const textAreaData = []
                let children = treeData.children
                while (children.length > 0) {
                    const textAreaItem = []
                    const newChildren = []
                    for (let i = 0; i < children.length; i++) {
                        textAreaItem.push({
                            addr: children[i].addr,
                            level: children[i].level,
                        })
                        newChildren.push(...children[i].children)
                    }
                    textAreaData.push(textAreaItem)
                    children = newChildren
                }
                setTextArea(textAreaData)
            })
            .catch((e) => {
                toast.warning(e.message)
            })
    }, [client, userData.addr])

    useEffect(() => {
        makeMemberTree()
    }, [makeMemberTree])

    return (
        <div className="w-full">
            <div className="mt-6 mb-1 ml-2 text-white">My Group</div>
            {textArea ? (
                <div className="flex w-full flex-col">
                    {textArea.map((group, groupIndex) => (
                        <div key={groupIndex} className="w-full flex-row">
                            <hr className="my-1 h-px w-full border-0 bg-neutral-600 dark:bg-neutral-600" />
                            <div className="mt-3 flex w-full flex-col">
                                <div className="mb-3 flex w-full flex-row">
                                    <div className="w-1/5 text-sm">{`Level ${
                                        groupIndex + 1
                                    }`}</div>
                                    <div className="w-3/5"></div>
                                    <div className="w-1/5 text-sm">
                                        {`${group.length} Account`}
                                    </div>
                                </div>
                                <div className="mb-3 flex flex-row justify-between">
                                    <div className="w-1/5 text-sm">#</div>
                                    <div className="w-3/5 text-center text-sm">
                                        Account ID
                                    </div>
                                    <div className="w-1/5 text-sm">Ranking</div>
                                </div>
                                {group.map((child, index) => (
                                    <div
                                        key={child.addr}
                                        className="mb-3 flex flex-row justify-between">
                                        <div className="w-1/5 text-sm">{`L${
                                            groupIndex + 1
                                        }-${index + 1}`}</div>
                                        <div className="w-3/5 text-center text-sm">
                                            {`${child.addr.slice(
                                                0,
                                                9
                                            )}...${child.addr.slice(-7)}`}
                                        </div>
                                        <div className="w-1/5 text-sm">
                                            {`V${child.level}`}
                                        </div>
                                    </div>
                                ))}
                                <div className="mb-8"></div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export default MemberTreeView
