import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { useAppConfig } from '../../../contexts/AppConfigProvider'

const useQuery = () => new URLSearchParams(useLocation().search)

const Register = () => {
    const navigate = useNavigate()
    const { updateParentAddr } = useAppConfig()
    const query = useQuery()

    useEffect(() => {
        updateParentAddr(query.get('ref') ?? '')
        navigate('/')
    }, [navigate, updateParentAddr, query])

    return <></>
}

export default Register
