import koreaImg from '../../../assets/city/korea.jpg'
import hokkaidoImg from '../../../assets/city/hokkaido.jpg'
import harbinImg from '../../../assets/city/harbin.jpg'
import umrahImg from '../../../assets/city/umrah.jpg'
import sydneyImg from '../../../assets/city/sydney.jpg'
import vietnamImg from '../../../assets/city/vietnam.jpg'
import gentingImg from '../../../assets/city/genting.png'
import rawaImg from '../../../assets/city/rawa.png'

const tours = [
    {
        title: 'Korea',
        image: koreaImg,
        stx: 1999,
        text: '8D 6N',
    },
    {
        title: 'Hokkaido',
        image: hokkaidoImg,
        stx: 4399,
        text: '7D 5N',
    },
    {
        title: 'Harbin China',
        image: harbinImg,
        stx: 2799,
        text: '8D 6N',
    },
    {
        title: 'Umrah',
        image: umrahImg,
        stx: 6500,
        text: '10D 9N',
    },
    {
        title: 'Sydney',
        image: sydneyImg,
        stx: 3799,
        text: '8D 5N',
    },
    {
        title: 'Vietnam',
        image: vietnamImg,
        stx: 1350,
        text: '5D 4N',
    },
]

const Tour = () => {
    return (
        <div className="flex w-full flex-col px-4 font-semibold text-white">
            <div className="mt-5 flex w-full flex-col">
                {tours.map((item) => (
                    <div
                        key={item.title}
                        className="mb-8 overflow-hidden rounded-xl bg-white text-black">
                        <img src={item.image} />
                        <div className="text-md mt-1 text-center">
                            {item.title}
                        </div>
                        <div className="flex flex-row items-center justify-between px-3 pb-3 text-sm">
                            <div className="">
                                {`${item.stx} MT `}
                                <span className="text-gray-300">/ pax</span>
                            </div>
                            <div className="flex items-center">
                                <div className="mr-2 rounded-full bg-[#f6c403] px-3 py-1 text-white">
                                    {item.text}
                                </div>
                                <div>All In</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Tour
