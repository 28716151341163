import { useCallback } from 'react'
import { TbLogout } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import CheckAuthentication from '../../../components/CheckAuthentication'
import { useAppConfig } from '../../../contexts/AppConfigProvider'
import { useAuth } from '../../../contexts/AuthProvider'
import { useWeb3Manage } from '../../../contexts/Web3ManageProvider'

const Header = () => {
    const { deactivate } = useWeb3Manage()
    const { currentPage } = useAppConfig()
    const { removeLogin } = useAuth()
    const navigate = useNavigate()

    const handleDeactivate = useCallback(() => {
        deactivate()
        removeLogin()
        navigate('/')
    }, [deactivate, navigate, removeLogin])

    return (
        <CheckAuthentication>
            <div className="sticky top-0 left-0 z-50 flex h-[70px] w-full flex-row items-center justify-between bg-[#1b3c5f] px-4 sm:h-[80px]">
                <div className="w-full text-center text-xl font-bold italic text-white">
                    {currentPage}
                </div>
                <div
                    className="text-white hover:cursor-pointer "
                    onClick={handleDeactivate}>
                    <TbLogout className="h-9 w-9" />
                </div>
            </div>
        </CheckAuthentication>
    )
}

export default Header
