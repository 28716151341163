import { Navigate, Route, Routes } from 'react-router-dom'
import MainHome from './MainHome'
import { ClaimList } from './ClaimList'
import Upgrade from './Upgrade'

const Home = () => {
    return (
        <Routes>
            <Route path="/" element={<MainHome />} />
            <Route path="/claim-list" element={<ClaimList />} />
            <Route path="/upgrade" element={<Upgrade />} />
            <Route path="/*" element={<Navigate to="/not-found" />} />
        </Routes>
    )
}

export default Home
