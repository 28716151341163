import CheckAuthentication from '../../../components/CheckAuthentication'
import MenuItem from '../../../components/MenuItem'
import menuList from '../../../config/menu'

const MenuBar = () => {
    return (
        <CheckAuthentication>
            <div className="bg-grayPrimary sticky bottom-0 left-0 flex h-[70px] w-full flex-row justify-around bg-gray-500 sm:h-[80px]">
                {menuList.map((item) => (
                    <MenuItem
                        key={item.name}
                        name={item.name}
                        url={item.url}
                        icon={item.icon}
                        title={item.title}
                    />
                ))}
            </div>
        </CheckAuthentication>
    )
}

export default MenuBar
