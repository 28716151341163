import earningPng from '../../../../assets/earning.png'
import missPng from '../../../../assets/miss.png'
import dollarPng from '../../../../assets/dollar.png'
import { useApolloClient } from '@apollo/client'
import {
    GET_STX_HISTORY,
    GET_ST_CLAIM_HISTORY,
    GET_UPGRADES,
    GET_USDT_HISTORY,
    GET_USDT_WITHDRAW_HISTORY,
    GET_USERS,
} from '../../../../apis/graphql/requests'
import { useAppConfig } from '../../../../contexts/AppConfigProvider'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const History = () => {
    const client = useApolloClient()
    const { userData } = useAppConfig()

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [histories, setHistories] = useState<Array<any>>()
    const [title, setTitle] = useState<string>('earned')

    const [walletObject, setWalletObject] = useState<Record<string, number>>()

    const makeMemberTree = useCallback(async () => {
        await client
            .query({
                query: GET_USERS,
                fetchPolicy: 'network-only',
                variables: {},
            })
            .then((response) => {
                const data = response.data.getUsers
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const initTree: { [key: string]: any } = {}
                for (let i = 0; i < data.length; i++) {
                    const addrIndex: string = data[i].addr
                    initTree[addrIndex] = { ...data[i], children: [] }
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const tree = data.reduce((prev: any, item: any) => {
                    if (!!item.parentAddr) {
                        if (prev[item.parentAddr]) {
                            prev[item.parentAddr].children.push(prev[item.addr])
                        }
                    }
                    return prev
                }, initTree)

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const treeData = tree[userData.addr!]
                const walletNumbers: Record<string, number> = {}

                let children = treeData.children
                let level = 1
                while (children.length > 0) {
                    const newChildren = []
                    for (let i = 0; i < children.length; i++) {
                        walletNumbers[children[i].addr] = level
                        newChildren.push(...children[i].children)
                    }
                    children = newChildren
                    level++
                }
                setWalletObject(walletNumbers)
            })
            .catch(() => {})
    }, [client, userData.addr])

    useEffect(() => {
        makeMemberTree()
    }, [makeMemberTree])

    const getUSDTHistory = useCallback(
        async (type: string) => {
            await client
                .query({
                    query: GET_USDT_HISTORY,
                    fetchPolicy: 'network-only',
                    variables: {
                        address: userData.addr,
                        type,
                    },
                })
                .then((response) => {
                    const data = response.data.getUsdtHistory
                    setHistories(data)
                })
                .catch((e) => {
                    toast.warning(e.message)
                })
        },
        [client, userData.addr]
    )

    const getSTClaimHistory = async () => {
        await client
            .query({
                query: GET_ST_CLAIM_HISTORY,
                fetchPolicy: 'network-only',
                variables: {
                    address: userData.addr,
                },
            })
            .then((response) => {
                const data = response.data.getClaimSTHistory
                setHistories(data)
            })
            .catch((e) => {
                toast.warning(e.message)
            })
    }

    const getUSDTWithdrawHistory = async () => {
        await client
            .query({
                query: GET_USDT_WITHDRAW_HISTORY,
                fetchPolicy: 'network-only',
                variables: {
                    address: userData.addr,
                },
            })
            .then((response) => {
                const data = response.data.getUsdtWithdrawHistory
                setHistories(data)
            })
            .catch((e) => {
                toast.warning(e.message)
            })
    }

    const getSTXHistory = async () => {
        await client
            .query({
                query: GET_STX_HISTORY,
                fetchPolicy: 'network-only',
                variables: {
                    address: userData.addr,
                },
            })
            .then((response) => {
                const data = response.data.getStxHistory
                setHistories(data)
            })
            .catch((e) => {
                toast.warning(e.message)
            })
    }

    const getUpgradeHistory = async () => {
        await client
            .query({
                query: GET_UPGRADES,
                fetchPolicy: 'network-only',
                variables: {
                    address: userData.addr,
                },
            })
            .then((response) => {
                const data = response.data.getUpgradeHistories.histories
                setHistories(data)
            })
            .catch((e) => {
                toast.warning(e.message)
            })
    }

    useEffect(() => {
        getUSDTHistory('earned')
        setTitle('earned')
    }, [getUSDTHistory])

    return (
        <div className="flex w-full flex-col px-4 text-white">
            <div className="mt-5 flex w-full flex-col">
                <div className="flex w-full flex-row gap-5 px-2 pb-3">
                    <div
                        onClick={() => {
                            getUSDTHistory('earned')
                            setTitle('earned')
                        }}
                        className={`flex w-1/2 flex-row items-center justify-between rounded-lg ${
                            title === 'earned'
                                ? ' bg-gradient-to-l'
                                : ' bg-gradient-to-r'
                        } xs:text-[9px]  from-[#2d5a83] to-[#04aded] py-3 px-1 text-[7px] shadow-sm shadow-blue-800/50 hover:cursor-pointer sm:px-3 sm:text-[12px]`}>
                        <img
                            className="mx-2 w-[30px] sm:w-[40px]"
                            src={earningPng}
                        />
                        <div className="w-full py-2 text-center">
                            Total Earning
                        </div>
                    </div>
                    <div
                        onClick={() => {
                            getUSDTHistory('missed')
                            setTitle('missed')
                        }}
                        className={`flex w-1/2 flex-row items-center justify-between rounded-lg  ${
                            title === 'missed'
                                ? ' bg-gradient-to-l'
                                : ' bg-gradient-to-r'
                        } xs:text-[9px]  from-[#2d5a83] to-[#04aded] py-3 px-1 text-[7px] shadow-sm shadow-blue-800/50 hover:cursor-pointer sm:px-3 sm:text-[12px]`}>
                        <img
                            className="mx-2 w-[30px] sm:w-[40px]"
                            src={missPng}
                        />
                        <div className="w-full py-2 text-center">
                            Total Miss
                        </div>
                    </div>
                </div>
                <div className="flex w-full flex-row gap-5 px-2 pb-3">
                    <div
                        onClick={() => {
                            getUpgradeHistory()
                            setTitle('upgrade')
                        }}
                        className={`flex w-1/2 flex-row items-center justify-between rounded-lg  ${
                            title === 'upgrade'
                                ? ' bg-gradient-to-l'
                                : ' bg-gradient-to-r'
                        } xs:text-[9px]  from-[#2d5a83] to-[#04aded] py-3 px-1 text-[7px] shadow-sm shadow-blue-800/50 hover:cursor-pointer sm:px-3 sm:text-[12px]`}>
                        <img
                            className="mx-2 w-[30px] sm:w-[40px]"
                            src={dollarPng}
                        />
                        <div className="w-full py-2 text-center">
                            Upgrade History
                        </div>
                    </div>
                    <div
                        onClick={() => {
                            getSTClaimHistory()
                            setTitle('st')
                        }}
                        className={`flex w-1/2 flex-row items-center justify-between rounded-lg ${
                            title === 'st'
                                ? ' bg-gradient-to-l'
                                : ' bg-gradient-to-r'
                        } xs:text-[9px]  from-[#2d5a83] to-[#04aded] py-3 px-1 text-[7px] shadow-sm shadow-blue-800/50 hover:cursor-pointer sm:px-3 sm:text-[12px]`}>
                        <img
                            className="mx-2 w-[30px] sm:w-[40px]"
                            src={dollarPng}
                        />
                        <div className="w-full py-2 text-center">
                            MTX claimed History
                        </div>
                    </div>
                </div>
                <div className="flex w-full flex-row gap-5 px-2 pb-3">
                    <div className="w-1/2 pr-2">
                        <div
                            onClick={() => {
                                getSTXHistory()
                                setTitle('stx')
                            }}
                            className={`flex w-full flex-row items-center justify-between rounded-lg  ${
                                title === 'stx'
                                    ? ' bg-gradient-to-l'
                                    : ' bg-gradient-to-r'
                            } xs:text-[9px]  from-[#2d5a83] to-[#04aded] py-3 px-1 text-[7px] shadow-sm shadow-blue-800/50 hover:cursor-pointer sm:px-3 sm:text-[12px]`}>
                            <img
                                className="mx-2 w-[30px] sm:w-[40px]"
                                src={dollarPng}
                            />
                            <div className="w-full py-2 text-center">
                                MT Total Earned
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(title === 'missed' || title === 'earned') && (
                <>
                    <div className="my-3 flex w-full flex-row rounded-md bg-gray-500 p-3 font-bold">
                        <div className="w-1/2 text-center">{`Total ${title}`}</div>
                        <div className="w-1/2">
                            {String(
                                histories?.reduce(
                                    (prev, item) =>
                                        parseFloat(item.amount) + prev,
                                    0
                                )
                            )}
                        </div>
                    </div>
                    {histories?.map((history) => (
                        <div
                            key={history.id}
                            className="mb-1 flex w-full flex-row rounded-lg bg-gray-500">
                            <div className="flex w-full flex-col p-2">
                                <div className="flex flex-row">
                                    <div className="w-1/2 text-center text-sm">
                                        Amount Received
                                    </div>
                                    <div className="w-1/2 text-sm">{`: ${history?.amount}`}</div>
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-1/2 text-center text-sm">
                                        Level
                                    </div>
                                    <div className="w-1/2 text-sm">{`: Level ${
                                        walletObject
                                            ? walletObject[history?.addr]
                                            : ``
                                    }`}</div>
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-1/2 text-center text-sm">
                                        Upgrade Ranking
                                    </div>
                                    <div className="w-1/2 text-sm">{`: V${history?.level}`}</div>
                                </div>
                                {title === 'earned' ? (
                                    <div className="flex flex-row">
                                        <div className="w-1/2 text-center text-sm">
                                            Earning Type
                                        </div>
                                        <div className="w-1/2 text-sm">
                                            :{' '}
                                            {history?.reason
                                                ?.split('-')[0]
                                                ?.charAt(0)
                                                ?.toUpperCase() +
                                                history?.reason
                                                    ?.split('-')[0]
                                                    ?.slice(1)}{' '}
                                            Bonus
                                        </div>
                                    </div>
                                ) : null}
                                <div className="flex flex-row">
                                    <div className="w-1/2 text-center text-sm">
                                        Date / Time
                                    </div>
                                    <div className="w-1/2 text-sm">{`: ${history?.createdAt}`}</div>
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-1/2 text-center text-sm">
                                        Wallet ID
                                    </div>
                                    <div className="w-1/2 text-sm">{`: ${history?.addr?.slice(
                                        0,
                                        9
                                    )}...${history?.addr?.slice(-7)}`}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            )}
            {title === 'st' &&
                histories?.map((history) => {
                    return (
                        <div
                            key={history.id}
                            className="mb-1 flex w-full flex-row rounded-lg bg-gray-500">
                            <div className="flex w-full flex-col p-2">
                                <div className="flex flex-row">
                                    <div className="w-1/2 text-center text-sm">
                                        Amount Received
                                    </div>
                                    <div className="w-1/2 text-sm">{`: ${history.amount}`}</div>
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-1/2 text-center text-sm">
                                        Date / Time
                                    </div>
                                    <div className="w-1/2 text-sm">
                                        {new Date(
                                            Number(history.createdAt)
                                        ).toDateString()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            {title === 'stx' && (
                <>
                    <div className="my-3 flex w-full flex-col gap-3">
                        <div className="flex w-full flex-row">
                            <div className="w-1/2 text-center">
                                Total Earned ( MT )
                            </div>
                            <div className="w-1/2 text-center">
                                {histories?.reduce(
                                    (prev, item) => prev + item.amount,
                                    0
                                )}
                            </div>
                        </div>
                    </div>
                    {histories?.map((history) => {
                        return (
                            <div
                                key={history.id}
                                className="mb-1 flex w-full flex-row rounded-lg bg-gray-500">
                                <div className="flex w-full flex-col p-2">
                                    <div className="flex flex-row">
                                        <div className="w-1/2 text-center text-sm">
                                            Amount Received
                                        </div>
                                        <div className="w-1/2 text-sm">{`: ${history.amount}`}</div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="w-1/2 text-center text-sm">
                                            Reason
                                        </div>
                                        <div className="w-1/2 text-sm">{`: ${history.reason}`}</div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="w-1/2 text-center text-sm">
                                            Date / Time
                                        </div>
                                        <div className="w-1/2 text-sm">
                                            {new Date(
                                                Number(history.createdAt)
                                            ).toDateString()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </>
            )}
            {title === 'upgrade' &&
                histories?.map((history) => {
                    return (
                        <div
                            key={history.id}
                            className="mb-1 flex w-full flex-row rounded-lg bg-gray-500">
                            <div className="flex w-full flex-col p-2">
                                <div className="flex flex-row">
                                    <div className="w-1/2 text-center text-sm">
                                        Upgraded Level
                                    </div>
                                    <div className="w-1/2 text-sm">{`: V${history?.upgradedLevel}`}</div>
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-1/2 text-center text-sm">
                                        Date / Time
                                    </div>
                                    <div className="w-1/2 text-sm">
                                        {`: ${history?.createdAt}`}
                                    </div>
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-1/2 text-center text-sm">
                                        Level Bonus Parent Address
                                    </div>
                                    <div className="w-1/2 text-sm">
                                        {history?.levelParentAddress !==
                                        'no-parent'
                                            ? `: ${history?.levelParentAddress?.slice(
                                                  0,
                                                  9
                                              )}...${history?.levelParentAddress?.slice(
                                                  -7
                                              )}`
                                            : ': admin'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
        </div>
    )
}

export default History
