import { GiReturnArrow } from 'react-icons/gi'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <div className="relative h-screen overflow-hidden bg-indigo-900">
            <img
                src="https://external-preview.redd.it/4MddL-315mp40uH18BgGL2-5b6NIPHcDMBSWuN11ynM.jpg?width=960&crop=smart&auto=webp&s=b98d54a43b3dac555df398588a2c791e0f3076d9"
                className="absolute h-full w-full object-cover"
            />
            <div className="absolute inset-0 bg-white opacity-25"> </div>
            <div className="j container relative z-10 mx-auto flex h-full justify-between px-6 py-32 md:px-12 xl:py-40">
                <div className="relative z-10 flex w-full flex-col items-center justify-center gap-52 font-mono">
                    <h1 className="mt-4 animate-bounce text-center text-5xl font-extrabold leading-tight text-yellow-300">
                        You are all alone here
                    </h1>
                    <Link to="/">
                        <div className="flex flex-row items-center justify-center gap-3 rounded-md bg-white px-5 py-2 ">
                            <GiReturnArrow className="h-9 w-9 text-red-500" />
                            <div className="text-xl font-bold text-red-500">
                                Return to Home
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default NotFound
