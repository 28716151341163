import pointIcon from '../../../../assets/point.png'
import { useAppConfig } from '../../../../contexts/AppConfigProvider'

const Wallet = () => {
    const { userData } = useAppConfig()

    return (
        <div className="flex h-full w-full flex-col gap-5">
            <div className="w-full px-3">
                <div className="flex flex-row items-center justify-between rounded-lg bg-[#141722] px-4 py-1 text-lg text-white hover:cursor-pointer hover:bg-gray-700">
                    <div className="flex flex-row items-center justify-center gap-3">
                        <img src={pointIcon} className="w-12" />
                        <p>MT</p>
                    </div>
                    <div className="pr-10">{`$ ${
                        userData?.stxAmount ? userData?.stxAmount.toFixed(3) : 0
                    }`}</div>
                </div>
            </div>
        </div>
    )
}

export default Wallet
