import React from 'react'
import { AiOutlineHome, AiOutlineUserAdd } from 'react-icons/ai'
import { GiCoins, GiChart } from 'react-icons/gi'
import { ItemType } from '../components/MenuItem'
import tourImg from '../assets/tour.png'

const menuList: Array<ItemType & { icon: React.ReactNode }> = [
    {
        name: 'Home',
        title: 'home',
        url: 'home',
        icon: <AiOutlineHome className="h-full w-full" />,
    },
    {
        name: 'Wallet',
        title: 'wallet',
        url: 'wallet',
        icon: <GiCoins className="h-full w-full" />,
    },
    {
        name: 'Tour',
        title: 'tour',
        url: 'tour',
        icon: <img src={tourImg} />,
    },
    {
        name: 'Invite',
        title: 'invite',
        url: 'invite',
        icon: <AiOutlineUserAdd className="h-full w-full" />,
    },
    {
        name: 'History',
        title: 'history',
        url: 'history',
        icon: <GiChart className="h-full w-full" />,
    },
]

export default menuList
