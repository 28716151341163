import { FC, PropsWithChildren } from 'react'
import Modal from 'react-modal'
import { useAppConfig } from '../contexts/AppConfigProvider'

const CustomModal: FC<
    PropsWithChildren<unknown> & {
        onAfterClose?: () => void
        className?: string
    }
> = ({ children, ...props }) => {
    const { openModal } = useAppConfig()

    return (
        <Modal
            isOpen={openModal}
            shouldCloseOnOverlayClick={false}
            onAfterClose={props.onAfterClose}
            className={`absolute focus-visible:outline-none ${props.className}`}>
            {children}
        </Modal>
    )
}

export default CustomModal
