import Auth from '../views/pages/auth'
import Register from '../views/pages/auth/Register'
import Landing from '../views/pages/Landing'
import NotFound from '../views/pages/NotFound'
import User from '../views/pages/user'
import History from '../views/pages/user/History'
import Home from '../views/pages/user/Home'
import Invite from '../views/pages/user/Invite'
import Tour from '../views/pages/user/Tour'
import Wallet from '../views/pages/user/Wallet'

export type PageType = 'main-page' | 'admin' | 'auth' | 'user'

export interface Page {
    type: PageType
    path: string
    element: React.ReactNode
}

const PAGE_LIST: Array<Page> = [
    {
        type: 'main-page',
        path: 'auth',
        element: <Auth />,
    },
    {
        type: 'main-page',
        path: 'user',
        element: <User />,
    },
    {
        type: 'main-page',
        path: '/',
        element: <Landing />,
    },
    {
        type: 'main-page',
        path: 'not-found',
        element: <NotFound />,
    },
    {
        type: 'auth',
        path: 'register',
        element: <Register />,
    },
    {
        type: 'user',
        path: 'home',
        element: <Home />,
    },
    {
        type: 'user',
        path: 'invite',
        element: <Invite />,
    },
    {
        type: 'user',
        path: 'history',
        element: <History />,
    },
    {
        type: 'user',
        path: 'wallet',
        element: <Wallet />,
    },
    {
        type: 'user',
        path: 'tour',
        element: <Tour />,
    },
]

export default PAGE_LIST
