import { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import { FaRegCopy } from 'react-icons/fa'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
    FacebookShareButton,
    FacebookIcon,
    LineShareButton,
    LineIcon,
    TelegramShareButton,
    TelegramIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from 'react-share'
import CheckAuthentication from '../../../components/CheckAuthentication'
import MemberTreeView from '../../../components/MemberTreeView'
import { useAppConfig } from '../../../contexts/AppConfigProvider'
import { toast } from 'react-toastify'

const Invite = () => {
    const { userData } = useAppConfig()

    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [referralLink, setReferralLink] = useState<string>('')

    useEffect(() => {
        const newReferral = `Register now for MT @ https://www.mtmtx.xyz/auth/register/?ref=${userData.addr}`
        setReferralLink(newReferral)
    }, [userData.addr])

    const handleTab = (s: number) => {
        setSelectedTab(s)
    }

    return (
        <CheckAuthentication>
            <div className="mt-5 flex flex-col px-5 text-white">
                <div className="flex w-full flex-row">
                    <div
                        className={`w-1/2 rounded-l-lg border-2 py-1 text-center hover:cursor-pointer ${
                            selectedTab === 0
                                ? `border-yellow-600 text-yellow-600`
                                : ``
                        }`}
                        onClick={() => handleTab(0)}>
                        My Invite
                    </div>
                    <div
                        className={`w-1/2 rounded-r-lg border-2 py-1 text-center hover:cursor-pointer ${
                            selectedTab === 1
                                ? `border-yellow-600 text-yellow-600`
                                : ``
                        }`}
                        onClick={() => handleTab(1)}>
                        My Team
                    </div>
                </div>
                {selectedTab === 0 ? (
                    <>
                        <div className="mt-10 w-full text-center font-mono text-lg font-bold text-white">
                            My Wallet ID
                        </div>
                        <div className="mt-4 flex w-full flex-row items-center justify-center gap-1">
                            <div className="overflow-hidden text-ellipsis text-sm text-white">
                                {userData.addr}
                            </div>
                            <CopyToClipboard text={userData.addr ?? ''}>
                                <FaRegCopy
                                    onClick={() => {
                                        toast.success(
                                            'Wallet address is copied!',
                                            {
                                                toastId: 'wallet copy',
                                            }
                                        )
                                    }}
                                    className="hover:cursor-pointer"
                                />
                            </CopyToClipboard>
                        </div>
                        <div className="mt-5 flex w-full justify-center">
                            <QRCode
                                size={256}
                                className="max-w-20 h-auto rounded-md bg-white p-5"
                                value={referralLink}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                        <div className="mt-5 w-full text-center text-sm">
                            Use A scan to bind the invitation relationship
                        </div>
                        <div className="mt-10 mb-5 flex w-full flex-row justify-around">
                            <CopyToClipboard text={referralLink}>
                                <div
                                    onClick={() => {
                                        toast.success(
                                            'Referral link is copied!',
                                            {
                                                toastId: 'referral copy',
                                            }
                                        )
                                    }}
                                    className="w-2/5 rounded-lg bg-yellow-600 py-2 text-center font-bold hover:cursor-pointer hover:bg-yellow-500">
                                    Copy Link
                                </div>
                            </CopyToClipboard>
                        </div>
                        <div className="mt-1 w-full pl-5 text-sm text-white">
                            Warm Reminder: Invite friend to bind ID account,
                        </div>
                        <div className="mt-1 w-full pl-5 text-sm text-white">
                            You'll earn the upgrade fee
                        </div>
                        <div className="mb-7 mt-5 flex flex-row justify-around px-5">
                            <TelegramShareButton url={referralLink}>
                                <div className="h-10 w-10 rounded-full bg-orange-400">
                                    <TelegramIcon
                                        size={40}
                                        round={true}
                                        iconFillColor="white"
                                        bgStyle={{ display: 'none' }}
                                    />
                                </div>
                            </TelegramShareButton>
                            <WhatsappShareButton url={referralLink}>
                                <div className="h-10 w-10 rounded-full bg-orange-400">
                                    <WhatsappIcon
                                        size={40}
                                        round={true}
                                        iconFillColor="white"
                                        bgStyle={{ display: 'none' }}
                                    />
                                </div>
                            </WhatsappShareButton>
                            <LineShareButton url={referralLink}>
                                <div className="h-10 w-10 rounded-full bg-orange-400">
                                    <LineIcon
                                        size={40}
                                        round={true}
                                        iconFillColor="white"
                                        bgStyle={{ display: 'none' }}
                                    />
                                </div>
                            </LineShareButton>
                            <FacebookShareButton url={referralLink}>
                                <div className="h-10 w-10 rounded-full bg-orange-400">
                                    <FacebookIcon
                                        size={40}
                                        round={true}
                                        iconFillColor="white"
                                        bgStyle={{ display: 'none' }}
                                    />
                                </div>
                            </FacebookShareButton>
                        </div>
                    </>
                ) : (
                    <MemberTreeView />
                )}
            </div>
        </CheckAuthentication>
    )
}

export default Invite
