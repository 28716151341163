import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { Web3ReactProvider } from '@web3-react/core'
import {
    Web3Provider,
    ExternalProvider,
    JsonRpcFetchFunc,
} from '@ethersproject/providers'

import { BrowserRouter as Router } from 'react-router-dom'

import View from './views/index'
import Loading from './components/Loading'
import AppConfigProvider from './contexts/AppConfigProvider'
import Modal from 'react-modal'
import Web3ManageProvider from './contexts/Web3ManageProvider'
import AuthProvider from './contexts/AuthProvider'
import AlantixApolloProvider from './contexts/AlantixApolloProvider'

function getLibrary(provider: ExternalProvider | JsonRpcFetchFunc) {
    return new Web3Provider(provider)
}

Modal.setAppElement('#root')

export default function App() {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <Web3ManageProvider>
                <Router>
                    <AppConfigProvider>
                        <AlantixApolloProvider>
                            <AuthProvider>
                                <View />
                                <ToastContainer />
                                <Loading />
                            </AuthProvider>
                        </AlantixApolloProvider>
                    </AppConfigProvider>
                </Router>
            </Web3ManageProvider>
        </Web3ReactProvider>
    )
}
