import { useEffect } from 'react'
import { FC, PropsWithChildren } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthProvider'
import { useWeb3Manage } from '../contexts/Web3ManageProvider'
import { useAppConfig } from '../contexts/AppConfigProvider'

const menus = ['home', 'wallet', 'invite', 'history', 'tour']

const CheckAuthentication: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const { isConnected } = useWeb3Manage()
    const { canLogin, removeLogin } = useAuth()
    const { isRegistrationPaymentNeeded, updateCurrentPage } = useAppConfig()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (!isConnected || !canLogin) {
            removeLogin()
            navigate('/')
        } else if (isRegistrationPaymentNeeded) {
            navigate('/user/home/upgrade')
        }
    }, [
        canLogin,
        isConnected,
        isRegistrationPaymentNeeded,
        navigate,
        removeLogin,
    ])

    useEffect(() => {
        let currentMenu = ''

        for (let i = 0; i < menus.length; i++) {
            if (location.pathname.indexOf(`/user/${menus[i]}`) === 0) {
                currentMenu = menus[i]
                break
            }
        }
        updateCurrentPage(currentMenu)
    }, [location.pathname, updateCurrentPage])

    return <> {children}</>
}

export default CheckAuthentication
