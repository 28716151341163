import Web3 from 'web3'
import { AbiItem } from 'web3-utils'

import { SmartTravellerABI, USDTABI } from '../../abis'
import {
    SmartTravellerContractAddress,
    USDTContractAddress,
} from '../../config/web3'

export const w3 = new Web3(Web3.givenProvider)
const SmartTravellerContract = new w3.eth.Contract(
    SmartTravellerABI as AbiItem[],
    SmartTravellerContractAddress
)
const USDTContract = new w3.eth.Contract(
    USDTABI as AbiItem[],
    USDTContractAddress
)

// USDT Contract Call Functions
export const getUsdtBalance = async (addr: string) =>
    USDTContract.methods.balanceOf(addr).call()
export const getAllowanceUSDT = async (addr: string) =>
    USDTContract.methods.allowance(addr, SmartTravellerContractAddress).call()
export const approveUSDT = async (addr: string, amount: string) => {
    const gasPrice = await w3.eth.getGasPrice()
    await USDTContract.methods
        .approve(SmartTravellerContractAddress, amount)
        .send({ from: addr?.toLowerCase(), gasPrice })
}
// Smart Traveller Contract Call Functions
export const upgradeLevel = async (
    addr: string,
    levelParent: string,
    referralParent: string,
    id: string
) => {
    const gasPrice = await w3.eth.getGasPrice()
    await SmartTravellerContract.methods
        .upgradeLevel(levelParent, referralParent, id)
        .send({ from: addr?.toLowerCase(), gasPrice })
}
