import { Navigate, Route } from 'react-router-dom'
import PAGE_LIST, { Page, PageType } from '../config/routes'

export const useRouteManage = () => {
    const getRoutes = (type: PageType) => {
        const routes = PAGE_LIST.filter((page: Page) => page.type === type).map(
            (page: Page) => {
                return (
                    <Route
                        key={`${type}${page.path}`}
                        path={`${page.path}/*`}
                        element={page.element}
                    />
                )
            }
        )
        routes.push(
            <Route
                path="*"
                key={`${type}error`}
                element={<Navigate to="/not-found" />}
            />
        )
        return routes
    }

    return {
        getRoutes,
    }
}
