export enum ChainIds {
    ETHEREUM = 1,
    ROPSTEN = 3,
    RINKEBY = 4,
    GÖRLI = 5,
    KOVAN = 42,
    POLYGON = 137,
    POLYGON_TESTNET = 80002,
    FANTOM = 250,
    FANTOM_TESTNET = 4002,
    XDAI = 100,
    BSC = 56,
    BSC_TESTNET = 97,
    ARBITRUM = 42161,
    ARBITRUM_TESTNET = 79377087078960,
    MOONBEAM_TESTNET = 1287,
    AVALANCHE = 43114,
    AVALANCHE_TESTNET = 43113,
    HECO = 128,
    HECO_TESTNET = 256,
    HARMONY = 1666600000,
    HARMONY_TESTNET = 1666700000,
    OKEX = 66,
    OKEX_TESTNET = 65,
    CELO = 42220,
    PALM = 11297108109,
    PALM_TESTNET = 11297108099,
    MOONRIVER = 1285,
    FUSE = 122,
    TELOS = 40,
    SOPHON = 57005, // cascadia,
}

export const isMainnet = process.env.REACT_APP_NETWORK === 'POLYGON'

export const CurrentChainId = isMainnet
    ? ChainIds.POLYGON
    : ChainIds.POLYGON_TESTNET

const CurrentChainName = isMainnet ? 'Polygon Mainnet' : 'Amoy Testnet'

const rpcUrls = isMainnet
    ? ['https://polygon-rpc.com']
    : ['https://polygon-amoy.drpc.org/']

export const CurrentNetworkData = {
    chainId: `0x${CurrentChainId.toString(16)}`,
    chainName: CurrentChainName,
    nativeCurrency: {
        name: 'POL',
        symbol: 'POL',
        decimals: 18,
    },
    rpcUrls,
    blockExplorerUrls: [
        isMainnet
            ? `https://polygonscan.com/`
            : `https://amoy.polygonscan.com/`,
    ],
}

export const SmartTravellerContractAddress =
    process.env.REACT_APP_SMART_TRAVELLER_CONTRACT_ADDRESS

export const USDTContractAddress = process.env.REACT_APP_USDT_CONTRACT_ADDRESS
