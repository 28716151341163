import { Routes } from 'react-router-dom'

import { useRouteManage } from '../../../hooks/useRouteManage'
import Header from '../../layout/user/Header'
import MenuBar from '../../layout/user/MenuBar'

const User = () => {
    const { getRoutes } = useRouteManage()

    return (
        <div className="relative flex w-full flex-col">
            <Header />
            <div className="min-h-[calc(100vh-140px)] bg-[#1b3c5f] sm:min-h-[calc(100vh-160px)]">
                <Routes>{getRoutes('user')}</Routes>
            </div>
            <MenuBar />
        </div>
    )
}

export default User
