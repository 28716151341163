import { useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'

import { Injected } from '../../contexts/Web3ManageProvider'

const useInactiveListener = (checkedConnect: boolean, connected: boolean) => {
    const { active, error, activate } = useWeb3React()

    useEffect(() => {
        const { ethereum } = window
        if (checkedConnect && ethereum && ethereum.on && !active && !error) {
            const handleChainChanged = () => {
                if (connected) activate(Injected)
            }

            const handleAccountsChanged = (accounts: string[]) => {
                if (accounts.length > 0 && connected) {
                    activate(Injected)
                }
            }

            const handleNetworkChanged = () => {
                if (connected) activate(Injected)
            }

            ethereum.on('chainChanged', handleChainChanged)
            ethereum.on('accountsChanged', handleAccountsChanged)
            ethereum.on('networkChanged', handleNetworkChanged)

            return () => {
                if (ethereum.removeListener) {
                    ethereum.removeListener('chainChanged', handleChainChanged)
                    ethereum.removeListener(
                        'accountsChanged',
                        handleAccountsChanged
                    )
                    ethereum.removeListener(
                        'networkChanged',
                        handleNetworkChanged
                    )
                }
            }
        }
    }, [active, error, checkedConnect, activate, connected])
}

export default useInactiveListener
